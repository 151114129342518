<script lang="ts" setup>
import type { StaticPagesListItem } from '~/types/StaticPageList';

const props = defineProps<{
  items: StaticPagesListItem[];
  title?: string | null;
  link?: string | null;
  isShowAnnotation?: boolean;
  isObserve?: boolean;
  blockName?: string;
}>();
const emits = defineEmits(['load-more']); // TODO implement load more functionality

const staticPageSliderItems = ref<any[]>([]);

function setItemRef(el: any) {
  // console.log(' set', el);

  if (el) {
    staticPageSliderItems.value.push(el);
  }
}

const { sendBannerEventToDataLayer } = useGtmModule();
const id = useId();

function sendSelectPromotionEvent(item: StaticPagesListItem, key: number) {
  sendBannerEventToDataLayer('select_promotion', {
    promotion_id: item.id,
    promotion_name: item.name,
    creative_slot: key,
    location_id: props.blockName,
  });
}

function scrollLeft() {
  const slider = document.getElementById(id);
  slider?.scrollBy({ left: -400, behavior: 'smooth' });
}

function scrollRight() {
  const slider = document.getElementById(id);
  slider?.scrollBy({ left: 400, behavior: 'smooth' });
}
</script>

<template>
  <section class="static-page-cards-gallery">
    <div
      v-if="title"
      class="px-3 lg:px-0"
    >
      <AppLink
        v-if="link"
        :to="link"
        class="w-fit"
      >
        <h2 class="inline underline underline-offset-4">
          {{ title }}
        </h2>
      </AppLink>

      <h2
        v-else
        class="inline"
      >
        {{ title }}
      </h2>
    </div>

    <div class="static-page-cards-gallery__slider">
      <div
        :id
        class="static-page-cards-gallery__slider-wrapper"
      >
        <AppLink
          v-for="(item, key) in items"
          :key="item.id"
          :ref="setItemRef"
          :to="makeUrlRelative(item.url)"
          class="static-page-cards-gallery__item"
          @click="sendSelectPromotionEvent(item, key)"
        >
          <StaticPageListItem
            :is-show-annotation="isShowAnnotation"
            :item
          />
        </AppLink>
      </div>

      <section
        v-if="items.length > 3"
        class="swiper-navigation"
      >
        <button
          class="swiper-button swiper-button--prev"
          @click="scrollLeft"
        >
          <SvgoChevronLeft
            class="text-2xl"
            filled
          />
        </button>

        <button
          class="swiper-button swiper-button--next"
          @click="scrollRight"
        >
          <SvgoChevronRight
            class="text-2xl"
            filled
          />
        </button>
      </section>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.static-page-cards-gallery {
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include screen-lg {
    @include inner-content;
  }

  &__slider {
    position: relative;
    display: flex;
    overflow: hidden;

    &:hover {
      .swiper-navigation {
        opacity: 1;
      }
    }
  }

  &__slider-wrapper {
    display: flex;
    gap: 12px;
    overflow: auto;
    touch-action: pan-x pan-y;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__item {
    flex-shrink: 0;
    width: 80%;
    height: 100%;

    &:first-of-type {
      margin-left: 12px;
    }

    &:last-of-type {
      margin-right: 12px;
    }

    @include screen-sm {
      width: 368px;
    }

    @include screen-lg {
      &:first-of-type {
        margin: 0;
      }

      &:last-of-type {
        margin: 0;
      }
    }
  }
}
</style>
